import Modal from './Modal';

import {
  COGNITO,
  FORGOT_PASSWORD,
  LOGIN_LOCATION,
  SIGN_IN,
  SIGN_UP,
} from '@/constants/LoginMethods';
import { fetchUserData } from '@/actions/UserActionCreators';
import { getString } from '@/content/i18n';
import { handleRedirect } from '@/router/clientUtils';
import { hideModal, showModal } from '@/actions/ModalActionCreators';
import { ModalId } from '@/constants/Modal';
import { S2LogoMarkOnly } from '@/components/shared/s2Logos';
import { searchStringToObj } from '@/router/routerUtils';
import Api from '@/api/Api';
import AuthApi, { LoginCancelledError, LoginFailedError, LoginForbiddenError } from '@/api/AuthApi';
import AuthEvent from '@/analytics/models/AuthEvent';
import AuthStore from '@/stores/AuthStore';
import Checkbox from '@/components/shared/common/form/Checkbox';
import CLAlert, { LEVEL, SEVERITY } from '@/components/library/alert/CLAlert';
import ContentWithHTML from '@/components/util/ContentWithHTML';
import EnvInfo from '@/env/EnvInfo';
import EventTarget from '@/analytics/constants/EventTarget';
import Feature from '@/weblab/Feature';
import GraphQLApi from '@/api/GraphQLApi';
import Icon from '@/components/shared/icon/Icon';
import IsDesktop from '@/components/util/env/IsDesktop';
import IsMobile from '@/components/util/env/IsMobile';
import LoginMethods from '@/components/shared/auth/LoginMethods';
import ModalStore from '@/stores/ModalStore';
import Redirect from '@/models/redirects/Redirect';
import S2Dispatcher from '@/utils/S2Dispatcher';
import S2History from '@/utils/S2History';
import softError from '@/utils/softError';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';
import trackRicoEvent from '@/analytics/trackRicoEvent';
import WeblabStore from '@/weblab/WeblabStore';

import classnames from 'classnames';
import idx from 'idx';
import PropTypes from 'prop-types';
import React from 'react';

export default class LoginModal extends React.PureComponent {
  static defaultProps = { loginAction: SIGN_IN };

  constructor(...args) {
    super(...args);

    this.state = {
      loginErrorMsg: null,
      hasAcceptedTerms: false,
    };
  }

  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    authApi: PropTypes.instanceOf(AuthApi).isRequired,
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    history: PropTypes.instanceOf(S2History).isRequired,
    modalStore: PropTypes.instanceOf(ModalStore).isRequired,
    graphQLApi: PropTypes.instanceOf(GraphQLApi).isRequired,
    weblabStore: PropTypes.instanceOf(WeblabStore).isRequired,
  };

  onClickOpenModal = () => {
    // These are used for the toggle within the modal that allows you to open the opposite sign in/sign up
    // modal (which is why they are seemingly backwards)
    if (this.props.loginAction === SIGN_IN) {
      this.context.dispatcher.dispatchEventually(
        showModal({ id: ModalId.SIGNUP, location: LOGIN_LOCATION.navBar })
      );
    } else {
      this.context.dispatcher.dispatchEventually(
        showModal({ id: ModalId.LOGIN, location: LOGIN_LOCATION.navBar })
      );
    }
  };

  onClickCloseModal = () => {
    this.context.dispatcher.dispatchEventually(hideModal());
  };

  onRequestLogin = args => {
    return this.login(args).catch(error => {
      softError('login.failed', 'unknown login error', error);
    });
  };

  async login({ method, action, email, password }) {
    const { api, authApi, authStore, dispatcher, history, modalStore, graphQLApi, weblabStore } =
      this.context;

    // State of features and experiments
    const isDemographicsModalOn = weblabStore.isFeatureEnabled(Feature.LoginDemographicsModal);

    // Data to be logged
    const analyticsData = {
      method,
      action,
      location: modalStore.getLocation() || undefined, // null is an unsupported data type in AnalyticsEvent, and will cause login to fail on Local
      subLocation: modalStore.getSubLocation() || undefined,
    };

    // Attempt login
    try {
      if (method === COGNITO && action === SIGN_IN) {
        if (!email || !password) {
          this.setState({
            loginErrorMsg: getString(_ => _.login.error.missingEmailPassword),
          });
          return;
        }
        trackAnalyticsEvent(
          AuthEvent.create({
            ...analyticsData,
            state: AuthEvent.State.STARTED,
          })
        );
        await authApi.loginViaPassword(method, email, password);
      } else if (action === FORGOT_PASSWORD) {
        trackAnalyticsEvent(
          AuthEvent.create({
            ...analyticsData,
            state: AuthEvent.State.STARTED,
          })
        );
        await authApi.loginViaForgotPassword(method);
      } else {
        trackAnalyticsEvent(
          AuthEvent.create({
            ...analyticsData,
            state: AuthEvent.State.STARTED,
          })
        );
        await authApi.loginViaOAuth(method);
      }
    } catch (error) {
      this.handleLoginError(error, method, action, analyticsData);
      return;
    }

    // Track completed login
    trackAnalyticsEvent(
      AuthEvent.create({
        ...analyticsData,
        state: AuthEvent.State.SUCCEEDED,
      })
    );

    // Track rico event for sign_up and sign_in event
    if (action === SIGN_UP || action === SIGN_IN) {
      const ricoData = {
        ...analyticsData,
        path: history.location.pathname,
        queryParams: history.location.search,
      };
      const actionEvent =
        action === SIGN_UP ? EventTarget.SIGN_UP : action === SIGN_IN ? EventTarget.SIGN_IN : null;

      trackRicoEvent(actionEvent, ricoData);
    }

    // when users hit the static login page, click 'create new account' and get redirected to the home page to create an acct
    // the url will include a 'redirect' query param to which we should route them to after successful login
    // this will bypass the demographic modal if it does redirect
    this.maybeRedirect();

    await dispatcher.dispatchEventually(hideModal());

    // Show demographics modal, if new users
    await fetchUserData({ api, authStore, graphQLApi, weblabStore });
    const user = authStore.getUser();
    if (user && !user.hasCompletedDemographics && isDemographicsModalOn) {
      await dispatcher.dispatchEventually(showModal({ id: ModalId.DEMOGRAPHIC }));
    }
  }

  maybeRedirect() {
    const { history } = this.context;
    const query = searchStringToObj(history.location.search);
    if (query.redirect) {
      const redirect = new Redirect({
        url: query.redirect,
      });
      handleRedirect({ history, redirect });
    }
  }

  // Since there are lots of ways login can fail, handle them all in one place
  handleLoginError(error, method, action, analyticsData) {
    // A technical error occurred during login
    if (error instanceof LoginFailedError) {
      trackAnalyticsEvent(
        AuthEvent.create({
          ...analyticsData,
          state: AuthEvent.State.FAILED,
        })
      );
      if (method === COGNITO) {
        this.showLoginModal({ action, method, hasLoginError: true });
      } else {
        this.hideLoginModal();
      }
      return;
    }

    // User cancelled login during provider's auth flow
    if (error instanceof LoginCancelledError) {
      trackAnalyticsEvent(
        AuthEvent.create({
          ...analyticsData,
          state: AuthEvent.State.CANCELLED,
        })
      );
      this.hideLoginModal();
      return;
    }

    // Provider determined login is not allowed
    if (error instanceof LoginForbiddenError) {
      trackAnalyticsEvent(
        AuthEvent.create({
          ...analyticsData,
          state: AuthEvent.State.FORBIDDEN,
        })
      );
      this.showLoginModal({ action, method, hasLoginError: true });
      return;
    }

    // Something else failed, but we don't know what it is
    trackAnalyticsEvent(
      AuthEvent.create({
        ...analyticsData,
        state: AuthEvent.State.FAILED,
      })
    );
    this.hideLoginModal();
  }

  hideLoginModal() {
    const { dispatcher } = this.context;
    dispatcher.dispatchEventually(hideModal());
  }

  showLoginModal({ action, method, hasLoginError }) {
    const { dispatcher } = this.context;
    dispatcher.dispatchEventually(
      showModal({
        id: action === SIGN_UP ? ModalId.SIGNUP : ModalId.LOGIN,
        data: {
          method,
          loginError: hasLoginError,
        },
      })
    );
  }

  toggleTermsAndConditions = () => {
    const { hasAcceptedTerms } = this.state;
    this.setState({ hasAcceptedTerms: !hasAcceptedTerms });
  };

  render() {
    const { loginAction, data } = this.props;
    const { hasAcceptedTerms, loginErrorMsg } = this.state;
    const isSignUp = loginAction === SIGN_UP;

    const isMobile = this.context.envInfo.isMobile;
    const actionStringKey = loginAction === SIGN_UP ? SIGN_UP : SIGN_IN;
    const adHeaderText = idx(data, _ => _.adHeaderText)
      ? data.adHeaderText
      : getString(_ => _.login.descriptionHeader);
    const adDescriptionText = idx(data, _ => _.adDescriptionText)
      ? data.adDescriptionText
      : getString(_ => _.login.descriptionText);

    const errorMessage = (() => {
      if (loginErrorMsg) {
        return loginErrorMsg;
      }
      if (data && data.loginError) {
        return getString(_ => _.login.method[data.method].error[loginAction]);
      }
      return null;
    })();

    return (
      <Modal
        darkBackground={true}
        modalId={loginAction === SIGN_UP ? ModalId.SIGNUP : ModalId.LOGIN}
        className={classnames({
          'sign-in-modal': true,
          'sign-in-modal__mobile': isMobile,
        })}>
        <div className="sign-in-modal__left-container">
          <h2 className="sign-in-modal__description-header">{adHeaderText}</h2>
          <p className="sign-in-modal__description">{adDescriptionText}</p>
          <S2LogoMarkOnly />
        </div>
        <div className="sign-in-modal__right-container">
          <IsMobile>
            <button
              className="sign-in-modal__close-btn"
              onClick={this.onClickCloseModal}
              aria-label={getString(_ => _.login.closeModalAriaLabel)}>
              <Icon icon="x" width="15" height="15" />
            </button>
          </IsMobile>
          {errorMessage && (
            <div className="sign-in-modal__error-container">
              <CLAlert level={LEVEL.FORM} severity={SEVERITY.ERROR}>
                {errorMessage}
              </CLAlert>
            </div>
          )}
          <h2 className="sign-in-modal__greeting-header">
            {getString(_ => _.login[actionStringKey].greetingText)}
          </h2>
          <p className="sign-in-modal__greeting-text">
            {getString(_ => _.login[actionStringKey].switchLoginTypeLinkLabel)}
            <button
              className="sign-in-modal__login-link link-button"
              onClick={this.onClickOpenModal}>
              {getString(_ => _.login[actionStringKey].switchLoginTypeLinkText)}
            </button>
          </p>
          {isSignUp && (
            <Checkbox
              onChange={this.toggleTermsAndConditions}
              value={hasAcceptedTerms}
              checkboxText={<ContentWithHTML content={_ => _.login.signUp.termsAndPrivacy} />}
            />
          )}
          <LoginMethods
            onRequestLogin={this.onRequestLogin}
            action={loginAction}
            isDisabled={isSignUp && !hasAcceptedTerms}
          />
          {!isSignUp && (
            <div className="sign-in-modal__terms-privacy_footer-text">
              <IsDesktop>
                <ContentWithHTML content={_ => _.login.signIn.termsAndPrivacy} />
              </IsDesktop>
              <IsMobile>
                <ContentWithHTML content={_ => _.login.signIn.termsAndPrivacyMobile} />
              </IsMobile>
            </div>
          )}
        </div>
        <IsDesktop>
          <button
            className="sign-in-modal__close-btn sign-in-modal__close-btn--desktop"
            onClick={this.onClickCloseModal}
            aria-label={getString(_ => _.login.closeModalAriaLabel)}>
            <Icon icon="x" width="15" height="15" />
          </button>
        </IsDesktop>
      </Modal>
    );
  }
}
